import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { CiBellOn } from "react-icons/ci";
import { IoIosMenu } from "react-icons/io";
import SidebarMobile from "./SidebarMobile";
import { HiOutlineInbox } from "react-icons/hi2";
import { HiOutlineChatBubbleLeftRight } from "react-icons/hi2";
import { IoPersonOutline, IoTrophyOutline } from "react-icons/io5";
import { IoSettingsOutline } from "react-icons/io5";
import { HiMiniChevronRight } from "react-icons/hi2";

function DashboardNavbar() {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  const navigate = useNavigate();
  const location = useLocation();
  const [toggleDashboardTab, setToggleDashboardTab] = useState(false);

  const avatar = user.profile_photo_path || "/default-avatar.png";

  useEffect(() => {
    if (toggleDashboardTab) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [toggleDashboardTab]);

  const searchParams = new URLSearchParams(location.search);
  const tab = searchParams.get("tab");

  // Check if the current URL matches the desired path
  const isMessagesTab = location.pathname.startsWith('/dashboard/messages');
  const isRegisterTab = isMessagesTab && tab === 'theRegister';
  const isDevregTab = isMessagesTab && tab === 'devreg';
  const isAdminTab = isMessagesTab && tab === 'admin';

  const isForumTab = location.pathname.startsWith('/dashboard/forum');
  const isSavedPostsTab = location.pathname === '/dashboard/forum/saved-posts';
  const isCategoryTab = location.pathname === '/dashboard/forum/category-viewed';
  const isNewPostTab = location.pathname === '/dashboard/forum/new-post';
  const isPostTab = location.pathname.startsWith('/dashboard/forum/post/');

  const isEditProfileTab = location.pathname === '/dashboard/edit-profile';
  const isDownloadCertTab = location.pathname === '/dashboard/certificate';
  const isSecurityTab = location.pathname.startsWith('/dashboard/account-settings');
  const isEarningsAndReferralsTab = location.pathname === '/dashboard/earnings-and-referrals';
  const isHelpTab = location.pathname === '/dashboard/help';

  const isOverviewTab = tab === 'overview';
  const isAdvertisementTab = tab === 'advertisement-features';
  const isLanguageTab = tab === 'language';
  const isWorkTab = tab === 'work-history';
  const isPortfolioTab = tab === 'portfolio';
  const isSkillsTab = tab === 'skills-and-expertise';
  const isEducationTab = tab === 'education-certificate';
  const isHonorTab = tab === 'honor-and-awards';

  const isChangePasswordTab = location.pathname === '/dashboard/account-settings/change-password';
  const isChangeEmailTab = location.pathname === '/dashboard/account-settings/change-email';
  const isChangeNumberTab = location.pathname === '/dashboard/account-settings/change-number';

  return (
    <div className="border-b border-neutral-300 border-solid">
      {toggleDashboardTab && (
        <div
          className=" w-[100vw] h-[100vh] bg-[#040e1d94] absolute z-[10] min-[768px]:hidden"
          onClick={() => setToggleDashboardTab(!toggleDashboardTab)}
        >
          <SidebarMobile />
        </div>
      )}
      <header className="max-w-[1366px] m-auto flex justify-between items-center max-md:flex-wrap max-md:max-w-full max-sm:px-2 max-sm:py-2 px-8 py-3">
        <div
          className="hamburger-menu px-1 py-1 bg-[#F2F7FF] rounded-xl max-sm:text-[30px] max-md:text-[40px] cursor-pointer min-[768px]:hidden"
          onClick={() => setToggleDashboardTab(!toggleDashboardTab)}
        >
          <IoIosMenu />
        </div>

        <div className="flex item-center gap-14">
          <img
            loading="lazy"
            src={"/loginLogo.png"}
            alt="Company logo"
            className="my-auto max-w-full w-[220px] cursor-pointer max-sm:w-[170px] max-[378px]:w-[120px] max-md:pl-6"
            onClick={() => {
              navigate("/dashboard/messages");
              window.scrollTo(0, 0);
            }}
          />
          <div className="flex items-center border-l border-gray-200 pl-4 max-[768px]:hidden">
            {isForumTab ? (
              <>
                <HiOutlineChatBubbleLeftRight size={30} color="white" className="bg-[#0082BA] p-1 rounded-full mr-4" />
                <span className="font-[600] text-[14px]">Forum</span>
                {isSavedPostsTab && (
                  <>
                    <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                    <span className="text-gray-600 font-light text-[14px]">Saved Posts</span>
                  </>
                )}
                {isNewPostTab && (
                  <>
                    <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                    <span className="text-gray-600 font-light text-[14px]">New Post</span>
                  </>
                )}
                {isPostTab && (
                  <>
                    <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                    <span className="text-gray-600 font-light text-[14px]">Post</span>
                  </>
                )}
                {isCategoryTab && (
                  <>
                    <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                    <span className="text-gray-600 font-light text-[14px]">Category</span>
                  </>
                )}
              </>
            ) : isEarningsAndReferralsTab ? (
              <>
                <IoTrophyOutline size={30} color="white" className="bg-[#0082BA] p-1 rounded-full mr-4" />
                <span className="font-[600] text-[14px]">Earnings & Referrals</span>
              </>
            ) : isEditProfileTab ? (
              <>
                <IoPersonOutline size={30} color="white" className="bg-[#0082BA] p-1 rounded-full mr-4" />
                <span className="font-[600] text-[14px]">Professional Profile</span>
                <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                {isOverviewTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Overview</span>
                ) : isAdvertisementTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Advertisement & Features</span>
                ) : isLanguageTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Language</span>
                ) : isWorkTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Work History</span>
                ) : isPortfolioTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Portfolio</span>
                ) : isSkillsTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Skills and Expertise</span>
                ) : isEducationTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Education / Certificate</span>
                ) : isHonorTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Honor and Awards</span>
                ) : (
                  <span className="text-gray-600 font-light text-[14px]">Edit Profile</span>
                )}
              </>
            ) : isDownloadCertTab ? (
              <>
                <IoPersonOutline size={30} color="white" className="bg-[#0082BA] p-1 rounded-full mr-4" />
                <span className="font-[600] text-[14px]">Professional Profile</span>
                <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                <span className="text-gray-600 font-light text-[14px]">Download Certificate</span>
              </>
            ) : isSecurityTab ? (
              <>
                <IoSettingsOutline size={30} color="white" className="bg-[#0082BA] p-1 rounded-full mr-4" />
                <span className="font-[600] text-[14px]">Account Settings</span>
                <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                {isChangePasswordTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Change Password</span>
                ) : isChangeEmailTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Change Email</span>
                ) : isChangeNumberTab ? (
                  <span className="text-gray-600 font-light text-[14px]">Change Phone Number</span>
                ) : (
                  <span className="text-gray-600 font-light text-[14px]">Security Setting</span>
                )}
              </>
            ) : isHelpTab ? (
              <>
                <IoSettingsOutline size={30} color="white" className="bg-[#0082BA] p-1 rounded-full mr-4" />
                <span className="font-[600] text-[14px]">Account Settings</span>
                <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                <span className="text-gray-600 font-light text-[14px]">Help</span>
              </>
            ) : isMessagesTab ? (
              <>
                <HiOutlineInbox size={30} color="white" className="bg-[#0082BA] p-1 rounded-full mr-4" />
                <span className="font-[600] text-[14px]">Messages</span>
                {isRegisterTab && (
                  <>
                    <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                    <span className="text-gray-600 font-light text-[14px]">The Register</span>
                  </>
                )}
                {isDevregTab && (
                  <>
                    <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                    <span className="text-gray-600 font-light text-[14px]">FullIDCheck</span>
                  </>
                )}
                {isAdminTab && (
                  <>
                    <HiMiniChevronRight size={15} className="text-[14px] mx-2" />
                    <span className="text-gray-600 font-light text-[14px]">Admin</span>
                  </>
                )}
              </>
            ) : null}
          </div>
        </div>
        <div className="flex item-center justify-center gap-3 px-2">
          <div className="notif-container my-auto relative max-sm:text-[23px] text-[28px]">
            <CiBellOn className="cursor-pointer" />
            <div className="notif-dot bg-[#2AD2C9] w-2 h-2 rounded-full absolute top-1 right-[2px]"></div>
          </div>
          <div className="profile-container flex item-center justify-center gap-3">
            <img
              alt="Avatar"
              className="profile-picture w-14 h-14 max-md:w-12 max-md:h-12 max-sm:w-10 max-sm:h-10 rounded-full hover:border-2 hover:border-[#2AD2C9] cursor-pointer"
              src={avatar}
            />
            <div className="name-credit flex items-start justify-center flex-col leading-5 max-md:hidden">
              <div className="name flex items-center justify-center font-bold gap-1 text-[#4A4A4A]">
                <div className="first-name">{user.first_name}</div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default DashboardNavbar;
